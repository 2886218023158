<template>
  <section>
    <b-card>
      <validation-observer ref="simpleRules">
        <b-form class="container">
          <b-row>
            <b-col md="12">
              <label>Termék *</label>

              <b-form-group>
                <v-select
                  v-model="selectedProduct"
                  label="name"
                  :options="allProducts()"
                  @input="selectProductId($event)"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <label>Mennyiség *</label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  mode="passive"
                >
                  <b-input-group :append="unit">
                    <b-form-input
                      v-model="formData.qty"
                      type="number"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <label>Raktár *</label>
              <b-form-group>
                <v-select
                  v-model="selectedWarehouse"
                  label="name"
                  :options="allWarehouses()"
                  @input="selectWarehouseId($event)"
                />
              </b-form-group>
            </b-col>
            <!-- submit button -->
            <b-col cols="12">
              <b-button
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                Mentés
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { mapGetters, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

import vSelect from 'vue-select'
import {
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormGroup,
  BForm,
  BCard,
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
  BOverlay,
} from 'bootstrap-vue'

export default {
  components: {
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCard,
    BCol,
    BButton,
    BFormCheckbox,
    BOverlay,
    vSelect,
    AppTimeline,
    AppTimelineItem,
  },
  data() {
    return {
      formData: {
        product_id: null,
        qty: null,
        warehouse_id: null,
      },
      selectedProduct: null,
      selectedWarehouse: null,
      unit: null,
      loading: false,
      required,
    }
  },
  methods: {
    ...mapActions([
      'createScrapping',
      'fetchScrappings',
      'fetchProducts',
      'fetchWarehouses',
    ]),
    ...mapGetters(['allProducts', 'allScrappings', 'allWarehouses']),
    initProducts() {
      this.loading = true
      this.fetchProducts().then(res => {
        this.loading = false
      })
    },
    initWarehouses() {
      this.loading = true
      this.fetchWarehouses().then(res => {
        this.loading = false
      })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.createScrapping(this.formData)
            .then(res => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Sikeres mentés',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.formData.product_id = null
              this.formData.qty = null
              this.formData.warehouse_id = null
              this.selectedProduct = null
              this.selectedWarehouse = null
              // this.initScrappings();
            })
            .catch(err => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Sikertelen mentés',
                  text: err.response.data.error,
                  icon: 'EditIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
    selectProductId(e) {
      this.formData.product_id = e.id
      this.unit = e.unit
    },
    selectWarehouseId(e) {
      this.formData.warehouse_id = e.id
    },
  },
  created() {
    this.initProducts()
    this.initWarehouses()
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
